<template>
  <div v-if="$hasFonction(user, 'Activer clients')">
    <div :hidden="hiddenLoader" id="loader" class="text-center">
      <b-icon-circle-fill animation="throb" font-scale="4"></b-icon-circle-fill>
    </div>

    <div>
      <div v-show="add">
        <app-collapse>
          <app-collapse-item
            isVisible
            title="Ajouter un client"
            v-if="$hasFonction(user, 'Ajouter clients')"
          >
            <!-- form start -->
            <validation-observer
              ref="addForm"
              class="d-flex justify-content-center"
            >
              <b-form class="col mt-2" @submit.prevent>
                <b-row>
                  <b-form-group class="col mr-2">
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <label for="name">Nom et Prénom</label>
                      <b-form-input
                        id="name"
                        v-model="defaultForm.name"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                        placeholder="Nom et Prénom"
                        @click="resetInputName"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small class="text-danger">{{ errorsName }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group class="col mr-2">
                    <validation-provider
                      #default="{ errors }"
                      name="phone"
                      rules="required"
                    >
                      <label for="phone">Téléphone</label>
                      <b-form-input
                        id="phone"
                        v-model="defaultForm.phone"
                        :state="errors.length > 0 ? false : null"
                        type="tel"
                        placeholder="Téléphone"
                        @click="resetInputPhone"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small class="text-danger">{{ errorsPhone }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group class="col mr-2">
                    <validation-provider
                      #default="{ errors }"
                      name="ifu"
                      rules="required"
                    >
                      <label for="ifu">Ifu</label>
                      <b-form-input
                        id="ifu"
                        v-model="defaultForm.ifu"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                        placeholder="Ifu"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <!-- <small class="text-danger">{{ errorsIfu }}</small> -->
                    </validation-provider>
                  </b-form-group>

                  <b-form-group class="col">
                    <validation-provider
                      #default="{ errors }"
                      name="reccm"
                      rules="required"
                    >
                      <label for="reccm">Reccm</label>
                      <b-form-input
                        id="reccm"
                        v-model="defaultForm.reccm"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                        placeholder="Reccm"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <!-- <small class="text-danger">{{ errorsReccm }}</small> -->
                    </validation-provider>
                  </b-form-group>
                </b-row>
                <!-- submit button start -->
                <div class="mt-2">
                  <b-button
                    class="mr-2"
                    variant="outline-secondary"
                    type="reset"
                    @click="resetInput"
                  >
                    Annuler
                  </b-button>

                  <b-button
                    v-if="etatButton"
                    variant="primary"
                    type="submit"
                    :disabled="isOperating"
                    @click.prevent="addClients"
                  >
                    Enregistrer
                  </b-button>

                  <b-button v-else variant="primary">
                    <b-icon-arrow-clockwise
                      animation="spin"
                      font-scale="1"
                    ></b-icon-arrow-clockwise>
                    En cours
                  </b-button>
                </div>
                <!-- submit button end -->
              </b-form>
            </validation-observer>
            <!-- form end -->
          </app-collapse-item>
        </app-collapse>
      </div>

      <div :hidden="hidden">
        <app-collapse>
          <app-collapse-item
            ref="collapse"
            title="Ajouter un client"
            v-if="$hasFonction(user, 'Ajouter clients')"
          >
            <!-- form start -->
            <validation-observer
              ref="addForm"
              class="d-flex justify-content-center"
            >
              <b-form class="col mt-2" @submit.prevent>
                <b-row>
                  <b-form-group class="col mr-2">
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <label for="name">Nom et Prénom</label>
                      <b-form-input
                        id="name"
                        v-model="defaultForm.name"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                        placeholder="Nom et Prénom"
                        @click="resetInputName"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small class="text-danger">{{ errorsName }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group class="col mr-2">
                    <validation-provider
                      #default="{ errors }"
                      name="phone"
                      rules="required"
                    >
                      <label for="phone">Téléphone</label>
                      <b-form-input
                        id="phone"
                        v-model="defaultForm.phone"
                        :state="errors.length > 0 ? false : null"
                        type="tel"
                        placeholder="Téléphone"
                        @click="resetInputPhone"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small class="text-danger">{{ errorsPhone }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group class="col mr-2">
                    <validation-provider
                      #default="{ errors }"
                      name="ifu"
                      rules="required"
                    >
                      <label for="ifu">Ifu</label>
                      <b-form-input
                        id="ifu"
                        v-model="defaultForm.ifu"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                        placeholder="Ifu"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <!-- <small class="text-danger">{{ errorsIfu }}</small> -->
                    </validation-provider>
                  </b-form-group>

                  <b-form-group class="col">
                    <validation-provider
                      #default="{ errors }"
                      name="reccm"
                      rules="required"
                    >
                      <label for="reccm">Reccm</label>
                      <b-form-input
                        id="reccm"
                        v-model="defaultForm.reccm"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                        placeholder="Reccm"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <!-- <small class="text-danger">{{ errorsReccm }}</small> -->
                    </validation-provider>
                  </b-form-group>
                </b-row>
                <!-- submit button start -->
                <div class="mt-2">
                  <b-button
                    class="mr-2"
                    variant="outline-secondary"
                    type="reset"
                    @click="resetInput"
                  >
                    Annuler
                  </b-button>

                  <b-button
                    v-if="etatButton"
                    variant="primary"
                    type="submit"
                    :disabled="isOperating"
                    @click.prevent="addClients"
                  >
                    Enregistrer
                  </b-button>

                  <b-button v-else variant="primary">
                    <b-icon-arrow-clockwise
                      animation="spin"
                      font-scale="1"
                    ></b-icon-arrow-clockwise>
                    En cours
                  </b-button>
                </div>
                <!-- submit button end -->
              </b-form>
            </validation-observer>
            <!-- form end -->
          </app-collapse-item>
        </app-collapse>

        <b-card no-body title="" class="mt-1">
          <div class="container-search-btn flex-stack my-2 mx-2">
            <!-- search start -->
            <div class="container-search mr-1">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Recherche"
              >
              </b-form-input>
            </div>
            <!-- search end -->

            <!-- bouton export start -->
			<div class="d-flex justify-content-end">
				<b-button
				v-if="$hasFonction(user, 'Exporter clients')"
				variant="outline-primary"
				size="sm"
				@click="ExportFomatExcel"
				>
				<b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
				Exporter
				</b-button>

        <b-button
				v-if="$hasFonction(user, 'Exporter clients')"
				variant="outline-primary"
				size="sm"
        class="ml-sm-2 mt-2 mt-sm-0"
				@click="ExportAllToFomatExcel"
				>
				<b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
				Tout Exporter
				</b-button>

			</div>
            <!-- bouton export end -->
          </div>

          <!-- table start -->
          <b-table
            v-model="ExcelClients"
            id="my-table"
            responsive="sm"
            :fields="headers"
            :items="clients"
            :per-page="perPage"
            :current-page="currentPage"
            :filter="filter"
            @filtered="onFiltered"
            show-empty
            class="border-bottom"
          >
            <!-- iconAction start -->
            <template #cell(actions)="data">
              <div class="row">
                <div>
                  <b-button
                    :id="'button-1' + data.item.id"
                    v-if="$hasFonction(user, 'Modifier clients')"
                    size="sm"
                    variant="flat-primary"
                    class="btn-icon"
                    @click="editClient(data.item)"
                  >
                    <feather-icon icon="Edit2Icon" />
                  </b-button>
                  <!-- <b-tooltip
                    :target="'button-1' + data.item.id"
                    title="Editer"
                    variant="dark"
                  ></b-tooltip> -->
                </div>
                <div>
                  <b-button
                    :id="'button-2' + data.item.id"
                    v-if="$hasFonction(user, 'Supprimer clients')"
                    size="sm"
                    variant="flat-danger"
                    @click="deleteClient(data.item)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                  <!-- <b-tooltip
                    :target="'button-2' + data.item.id"
                    title="Supprimer"
                    variant="dark"
                  ></b-tooltip> -->
                </div>
              </div>
            </template>
            <!-- iconAction end -->

            <template #empty>
              <div class="text-center">
                <span class="text-nowrap">Aucune donnée existante</span>
              </div>
            </template>
            <template #emptyfiltered>
              <div class="text-center">
                <span class="text-nowrap"
                  >L'élément recherché n'est pas dans la liste</span
                >
              </div>
            </template>
          </b-table>
          <!-- table end -->

          <div class="my-2 mx-2 row">
            <!-- per-page end -->
            <div class="col">
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                style="width: 70px"
              ></b-form-select>
              <span class="text-nowrap ml-2" v-if="allRows != 0"> 1 - <span v-if="perPage >= allRows">{{ allRows }}</span> 
	                  <span v-if="perPage < allRows"> {{perPage}} </span> sur {{ allRows }}
                    </span>
              <span class="text-nowrap ml-2" v-if="allRows == 0"> 0 éléments</span>
            </div>
            <!-- per-page end -->

            <!-- pagination start -->
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              aria-controls="my-table"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <!-- pagination end -->
          </div>
        </b-card>

        <!-- modal edit start  -->
        <b-modal
          id="modal-edit"
          v-model="modalEdit"
          :ok-disabled="isOperating"
          title="Modifier les informations"
          @hidden="resetModal"
          hide-footer
          centered
        >
          <!-- form start -->
          <b-form ref="editForm" @submit.stop.prevent="handleSubmit">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <label for="name">Nom et Prénom</label>
                <b-form-input
                  id="name"
                  placeholder="Nom et Prénom"
                  type="text"
                  v-model="editedForm.name"
                  :state="errors.length > 0 ? false : null"
                  @click="resetInputNameModal"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger">{{
                  errors[0] ? "" : errorsNameModal
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="phone"
                rules="required"
              >
                <label for="phone">Téléphone</label>
                <b-form-input
                  id="phone"
                  type="tel"
                  v-model="editedForm.phone"
                  placeholder="Téléphone"
                  :state="errors.length > 0 ? false : null"
                  @click="resetInputPhoneModal"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger">{{
                  errors[0] ? "" : errorsPhoneModal
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="ifu"
                rules="required"
              >
                <label for="ifu">Ifu</label>
                <b-form-input
                  id="ifu"
                  type="text"
                  v-model="editedForm.ifu"
                  placeholder="Ifu"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger">{{
                  errors[0] ? "" : errorsIfuModal
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="reccm"
                rules="required"
              >
                <label for="reccm">Reccm</label>
                <b-form-input
                  id="reccm"
                  type="text"
                  v-model="editedForm.reccm"
                  placeholder="Reccm"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger">{{
                  errors[0] ? "" : errorsReccmModal
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group class="mb-0">
              <b-button
                variant="outline-secondary"
                type="reset"
                class="mt-2 mr-1"
                @click="modalEdit = false"
              >
                Annuler
              </b-button>

              <b-button
                v-if="etatButtonEdit"
                variant="primary"
                class="mt-2"
                :disabled="isOperating"
                @click="handleOk"
              >
                Enregistrer
              </b-button>

              <b-button v-else variant="primary" class="mt-2">
                <b-icon-arrow-clockwise
                  animation="spin"
                  font-scale="1"
                ></b-icon-arrow-clockwise>
                En cours
              </b-button>
            </b-form-group>
          </b-form>
          <!-- form end -->
        </b-modal>
        <!-- modal edit end-->
      </div>

      <div :hidden="hiddenEmpty" class="text-center">
        <empty-list></empty-list>
        <b-button variant="primary" size="sm" @click="etatAdd">
          <b-icon-plus></b-icon-plus>
          AJOUTER
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { json2excel, excel2json } from "js2excel";
import downloadExcel from "json2excel-js";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import {
  BCard,
  BTable,
  BCardText,
  BAlert,
  BModal,
  BPagination,
  BButton,
  BIconPlus,
  BIconPencilFill,
  BIconTrashFill,
  BIconArrowClockwise,
  BIconCircleFill,
  BIconFileEarmarkArrowDownFill,
  BForm,
  BRow,
  BCol,
  BTooltip,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BCollapse,
  BFormInvalidFeedback,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BTable,
    BModal,
    BCardText,
    BAlert,
    BCardCode,
    BPagination,
    BButton,
    EmptyList,
    BIconPlus,
    BIconPencilFill,
    BIconTrashFill,
    BIconArrowClockwise,
    BIconCircleFill,
    BIconFileEarmarkArrowDownFill,
    BForm,
    BRow,
    BCol,
    BTooltip,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BCollapse,
    BFormInvalidFeedback,
    AppCollapse,
    AppCollapseItem,
    ValidationProvider,
    ValidationObserver,
    json2excel,
    excel2json,
    downloadExcel,
  },
  data() {
    return {
      etatButton: true,
      etatButtonEdit: true,
      add: false,
      hidden: true,
      hiddenEmpty: true,
      hiddenLoader: false,
      isOperating: false,
      required,
      integer,
      alpha,
      alphaDash,
      nameState: null,
      errorsName: null,
      errorsPhone: null,
      errorsIfu: null,
      errorsReccm: null,
      errorsNameModal: null,
      errorsPhoneModal: null,
      errorsIfuModal: null,
      errorsReccmModal: null,
      modalEdit: false,
      // totalRows: 1,
      allRows: 0,
      perPage: 5,
      currentPage: 1,
      filter: null,
      pageOptions: [],
      headers: [
        // {
        // 	key: '#',
        // 	label: '',
        // 	sortable: false,
        // },
        {
          key: "name",
          label: "Nom & Prénom",
          sortable: true,
        },
        {
          key: "ifu",
          label: "Ifu",
          sortable: true,
        },
        {
          key: "reccm",
          label: "Reccm",
          sortable: true,
        },
        {
          key: "phone",
          label: "Téléphone",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
          class: "w-25",
        },
      ],
      clients: [],
      ExcelClients: null,
      editedForm: {
        name: "",
        phone: "",
        ifu: "",
        reccm: "",
      },
      defaultForm: {
        name: "",
        phone: "",
        ifu: "",
        reccm: "",
      },
    };
  },
  mounted() {},
  computed: {
    ...mapState({
      user: (state) => state.user.auth,
    }),
    totalRows() {
      this.pageOptions = [5, 10, 25, 50, 100, { value: this.clients.length, text: "Tout" } ]
      return this.allRows;
    },
  },
  created() {
    this.getClients();
  },
  methods: {
    etatAdd() {
      this.add = true;
      this.hiddenEmpty = true;
    },

    onFiltered(filteredItems) {
        this.allRows =  filteredItems.length 
        this.currentPage = 1
      },


    resetForm() {
      this.defaultForm.name = "";
      this.defaultForm.phone = "";
      this.defaultForm.ifu = "";
      this.defaultForm.reccm = "";
    },

    resetModal() {
      this.editedForm.name = "";
      this.editedForm.phone = "";
      this.editedForm.ifu = "";
      this.editedForm.reccm = "";
      this.nameState = null;
      this.resetInputModal();
    },

    resetInput() {
      this.resetInputName();
      this.resetInputPhone();
      this.resetInputIfu();
      this.resetInputReccm();
    },

    resetInputName() {
      if (this.errorsName) {
        this.errorsName = null;
      }
    },

    resetInputPhone() {
      if (this.errorsPhone) {
        this.errorsPhone = null;
      }
    },

    resetInputIfu() {
      if (this.errorsIfu) {
        this.errorsIfu = null;
      }
    },

    resetInputReccm() {
      if (this.errorsReccm) {
        this.errorsReccm = null;
      }
    },

    resetInputModal() {
      this.resetInputNameModal();
      this.resetInputPhoneModal();
      this.resetInputIfuModal();
      this.resetInputReccmModal();
    },

    resetInputNameModal() {
      if (this.errorsNameModal) {
        this.errorsNameModal = null;
      }
    },

    resetInputPhoneModal() {
      if (this.errorsPhoneModal) {
        this.errorsPhoneModal = null;
      }
    },

    resetInputIfuModal() {
      if (this.errorsIfuModal) {
        this.errorsIfuModal = null;
      }
    },

    resetInputReccmModal() {
      if (this.errorsReccmModal) {
        this.errorsReccmModal = null;
      }
    },

    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },

    getClients() {
      this.$http
        .get("/clients")
        .then((res) => {
          if (res.status) {
            this.hiddenLoader = true;
          }

          this.clients = res.data.data.reverse();

          if (this.clients.length) {
            this.hidden = false;
            this.hiddenEmpty = true;
          } else {
            this.hidden = true;
            this.hiddenEmpty = false;
          }

          this.allRows = this.clients.length
        })
        .catch((e) => {
          console.log("erreur de récupération:" + e.response.data.message);
        });
    },

    addClients() {
      this.$refs.addForm.validate().then((success) => {
        if (success) {
          this.etatButton = false;
          this.$http
            .post("/clients", this.defaultForm)
            .then((result) => {
              if (result.data.success) {
                this.etatButton = true;
                this.resetInput();
                this.defaultForm = JSON.parse(
                  JSON.stringify({ type: Object, default: () => {} })
                );
                this.add = false;
                // this.hidden = false;
                this.showToast("Client ajouté avec succès", "success");
                this.$refs.addForm.reset();
                if (this.clients.length) {
                  this.$refs.collapse.updateVisible(false);
                }
                this.getClients();
                // this.resetForm()
              } else {
                this.etatButton = true;
                this.showToast(result.data.message, "danger");
              }
            })
            .catch((e) => {
              this.etatButton = true;
              var err = e.response.data.errors;
              // this.isOperating = false;

              if (err) {
                if (err.name) {
                  this.errorsName = err.name[0];
                }

                if (err.phone) {
                  this.errorsPhone = err.phone[0];
                }

                if (err.ifu) {
                  this.errorsIfu = err.ifu[0];
                }

                if (err.reccm) {
                  this.errorsReccm = err.reccm[0];
                }
              }
            });
        }
      });
    },

    checkFormValidity() {
      const valid = this.$refs.editForm.checkValidity();
      this.nameState = valid;
      return valid;
    },

    handleSubmit() {
      // 	if (success) {
      if (!this.checkFormValidity()) {
        return;
      }
      this.etatButtonEdit = false;
      this.$http
        .put("/clients/" + this.editedForm.id, this.editedForm)
        .then((result) => {
          if (result.data.success) {
            this.etatButtonEdit = true;
            this.resetInputModal();
            this.editedForm = JSON.parse(
              JSON.stringify({ type: Object, default: () => {} })
            );
            this.modalEdit = false;
            this.showToast("Client modifié avec succès", "success");
            this.getClients();
            this.$refs.addForm.reset();
          } else {
            this.etatButtonEdit = true;
            this.showToast(result.data.message, "danger");
          }
        })
        .catch((e) => {
          this.etatButtonEdit = true;
          console.log("erreur modification:" + e);
          // this.isOperating = false;
          var err = e.response.data.errors;
          // this.isOperating = false;

          if (err.name) {
            this.errorsNameModal = err.name[0];
          }

          if (err.phone) {
            this.errorsPhoneModal = err.phone[0];
          }

          if (err.ifu) {
            this.errorsIfuModal = err.ifu[0];
          }

          if (err.reccm) {
            this.errorsReccmModal = err.reccm[0];
          }
        });
      // 	}
      // });

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },

    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      // Trigger submit handler
      this.handleSubmit();
    },

    editClient(item) {
      this.editedForm = Object.assign({}, item);
      this.modalEdit = true;
    },

    deleteClient(item) {
      // Message de suppression
      this.$bvModal
        .msgBoxConfirm(
          "Voulez-vous vraiment supprimer cet enregistrement de la liste des clients ?",
          {
            title: "Supprimer un client",
            okVariant: "danger",
            cancelVariant: "outline-secondary",
            okTitle: "Confirmer",
            cancelTitle: "Annuler",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value === true) {
            this.$http
              .delete("/clients/" + item.id)
              .then((result) => {
                if (result.data.success) {
                  this.showToast("Supression effectuée avec succès", "success");
                  this.getClients();
                } else {
                  this.showToast(result.data.message, "danger");
                }
              })
              .catch((err) => {
                this.showToast(err.response.data.message, "error");
              });
          }
        });
    },

    ExportFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers
      let data = [];
      let obj = {};

      this.ExcelClients.forEach((element) => {
        obj = {
          "NOM & PRÉNOM": element.name,
          IFU: element.ifu,
          RECCM: element.reccm,
          TÉLÉPHONE: element.phone,
        };

        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste des Clients",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },


    ExportAllToFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers
      let data = [];
      let obj = {};

      this.clients.forEach((element) => {
        obj = {
          "NOM & PRÉNOM": element.name,
          IFU: element.ifu,
          RECCM: element.reccm,
          TÉLÉPHONE: element.phone,
        };

        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste des Clients",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },

  },
};
</script>

<style>
	#loader {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: #f8f8f8;
		padding-top: 15%;
		z-index: 1000;
	}

	.container-search-btn {
		display: flex !important;
	}

	.flex-stack {
		justify-content: space-between;
		align-items: center;
	}
	
	@media screen and (max-width: 435px) {
		.container-search-btn {
			display: flex !important;
		}

		.container-search {
			width: 50%;
		}
	}

	@media screen and (max-width: 327px) {
		.container-search {
			width: 100%;
			margin-bottom: 10px;
		}

		.container-search-btn {
			display: block !important;
		}
	}
</style>